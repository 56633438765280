<template>
  <el-form class="project-search-bar-wrapper" :inline="true" size="small">
    <el-form-item label="" prop="projectID">
      <el-cascader filterable clearable v-model="cascaderValue" :props="{ lazy: true, lazyLoad: lazyLoad }" @change="cascaderValueChange"></el-cascader>
    </el-form-item>
  </el-form>
</template>

<script>
import { getUserCorporationList } from '@/api/role.js';
import { getUserInformation } from '@/utils/auth.js';
import { queryProjectListByCpID } from '@/api/project.js';
import statusInclude from '@/utils/statusCode';

export default {
  name: 'SearchBarInProject',
  data() {
    return {
      cascaderValue: '',
      siteName: '',
      cpId: '',
      pId: '',
      userId: '',
      CPList: [],
    };
  },
  created() {
    let u = getUserInformation();
    if (u && u.userInformation && u.userInformation.userId) {
      this.userId = u.userInformation.userId;
    }
  },
  methods: {
    async lazyLoad(node, resolve) {
      const { level, root } = node;
      let nodes = [];
      if (root) {
        let { code, reason, data } = await getUserCorporationList(this.userId);
        if (statusInclude(code)) {
          let CPList = data;
          if (CPList.length == 1) {
            let res = await queryProjectListByCpID(CPList[0].cpId);
            if (res?.data) {
              for (let item of res.data) {
                let node = { value: item.pId, label: item.pName, leaf: true };
                nodes.push(node);
              }
            } else {
            }
          } else {
            for (let item of CPList) {
              let node = { value: item.cpId, label: item.cpName, leaf: false };
              nodes.push(node);
            }
          }
        } else {
          this.$message.error(reason);
        }
      } else {
        if (level == 1) {
          let res = await queryProjectListByCpID(node.data.value);
          if (res?.data) {
            for (let item of res.data) {
              let node = { value: item.pId, label: item.pName, leaf: true };
              nodes.push(node);
            }
          } else {
          }
        }
      }

      resolve(nodes);
    },
    cascaderValueChange(v) {
      this.siteName = '';
      if (v.length > 0) {
        if (v.length == 1) {
          this.pId = v[0];
        } else {
          this.cpId = v[0];
          this.pId = v[1];
        }
      } else {
        this.pId = '';
      }
      this.$emit('pId', this.pId);
      this.$emit('cpId', this.cpId);
    },
  },
};
</script>
