<template>
  <div class="main-zone">
    <div v-if="edit" class="cancel">
      <el-button size="small" type="primary" @click="setGeometry(GEOMTYPE.circle)">圆形</el-button>
      <el-button size="small" type="primary" @click="setGeometry(GEOMTYPE.polygon)">多边形</el-button>
      <el-button size="small" type="primary" @click="doCancel">取消</el-button>
    </div>
    <div v-else class="cancel">
      <el-button size="small" type="primary" @click="importLayerHandler">导入图层</el-button>
    </div>

    <div :class="['modal-left', shrinkLeft ? 'modal-shrink' : '']">
      <div class="common-header">
        <div class="dot" />
        <div class="title">
          {{ ID ? '编辑禁飞区' : edit ? '新建禁飞区' : '禁飞区管理' }}
        </div>
      </div>
      <img v-if="!shrinkLeft" class="shrink" src="@/assets/images/mission/task/shrink.png" @click="shrinkLeft = !shrinkLeft" />
      <img v-else class="shrink" src="@/assets/images/mission/task/expand.png" @click="shrinkLeft = !shrinkLeft" />
      <div class="layer-mainer x-scroll">
        <template v-if="edit">
          <el-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <div class="task-name">
              <el-input v-model="forbiddenZone.MCName">
                <img slot="suffix" src="@/assets/images/mission/task/edit.png" alt="" />
              </el-input>
            </div>
            <div class="task-address">
              <img src="@/assets/images/mission/task/address.png" alt="" />
              <span>{{ address || '位置信息（请在地图上选点）' }}</span>
            </div>
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>项目选择</span>
            </div>
            <div>
              <search-bar slot="header" @pId="forbiddenZonePIDHandler" @cpId="forbiddenZoneCIDHandler"></search-bar>
            </div>

            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>站点选择</span>
            </div>
            <el-form-item label="">
              <el-select v-model="forbiddenZone.siteID" popper-class="black-theme-center" style="width: 100%" clearable @change="forbiddenZoneSiteIDHandler">
                <el-option v-for="item in siteList" :key="Math.random() + item.siteID" :value="item.siteID" :label="item.siteName"></el-option>
              </el-select>
            </el-form-item>
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>选择时间（选填）</span>
            </div>

            <el-form-item label="">
              <el-time-picker v-model="validTime" :is-range="true" range-separator="~" size="small" value-format="HH:mm" prefix-icon="" :editable="false" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="validTimeHandler" />
            </el-form-item>

            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>选择日期（选填）</span>
            </div>
            <el-form-item label="">
              <el-date-picker type="daterange" v-model="validDate" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="validDateHandler" />
            </el-form-item>
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>底部高度</span>
            </div>
            <el-form-item label="">
              <el-input v-model="forbiddenZone.bottomHt">
                <template slot="append">m</template>
              </el-input>
            </el-form-item>
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>顶部高度</span>
            </div>
            <el-form-item label="">
              <el-input v-model="forbiddenZone.topHt">
                <template slot="append">m</template>
              </el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <el-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>项目选择</span>
            </div>
            <el-form-item label="">
              <search-bar @pId="handleSearchPID"></search-bar>
            </el-form-item>
            <div class="task-address">
              <img src="@/assets/images/layers/site.png" alt="" />
              <span>站点选择</span>
            </div>
            <el-form-item label="">
              <el-select v-model="forbiddenZone.siteID" popper-class="black-theme-center" style="width: 100%" clearable @change="siteIDChangeHandler">
                <el-option v-for="item in siteList" :key="Math.random() + item.siteID" :value="item.siteID" :label="item.siteName"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <div class="zone-list x-scroll">
            <div v-for="(item, index) in list" :key="'zone_' + index" class="zone" :class="ID === item.ZID || index === hilightIndex ? 'active' : ''" @click="selectZone(item.ZID, index)">
              <div class="top">
                <div class="type-pic">
                  <img v-if="item.type == 2" src="@/assets/images/layers/temp.png" />
                  <img v-else src="@/assets/images/layers/permanent.png" />
                </div>
                <div class="main">
                  <div class="name">
                    {{ item.MCName }}
                  </div>
                </div>
                <div class="op">
                  <div class="more" style="margin-right: -10px" @click="moreHandler(item.ZID)">
                    <img src="@/assets/images/layers/more.png" style="margin-right: 10px" />
                  </div>
                  <div>
                    <el-switch v-model="item.active" @change="switchChage($event, index, item)" />
                  </div>

                  <div v-if="editZID === item.ZID" class="edit">
                    <div v-if="item.isValid" @click="beginEdit(item.ZID)">编辑</div>
                    <div @click="beginDel(item)">删除</div>
                  </div>
                </div>
              </div>
              <div class="date">面积：30km²</div>
              <div class="date">类型：{{ zoneType(item.type) }}</div>
              <div class="date">
                <img src="@/assets/images/layers/time.png" alt="" />
                {{ $moment(item.updateTime ? item.updateTime : item.createTime).format('YYYY-MM-DD HH:mm') }}
              </div>
            </div>
          </div>
        </template>

        <div class="xl-button">
          <div @click="btnHandler">
            <img v-if="edit" src="@/assets/images/layers/submit.png" alt="" />
            <img v-else src="@/assets/images/layers/add.png" alt="" />
            <span>
              {{ edit ? '提交禁飞区' : '新建禁飞区' }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="map-custom">
      <ol-map ref="mainMap" :allow3-d="false" @postrender="init" />
    </div>

    <el-dialog :close-on-click-modal="false" width="350px" top="20%" :visible="dialogVisible" :before-close="handleClose" title="新建空域图层">
      <el-form>
        <el-form-item label="空域类型:">
          <el-select v-model="forbiddenZone.MCType">
            <el-option label="禁飞区" :value="MCTYPE.forbidden" />
            <el-option label="限飞区" :value="MCTYPE.limit" />
            <el-option label="授权空域" :value="MCTYPE.auth" />
            <el-option label="作业区" :value="MCTYPE.work" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="layerImported">
          <el-upload class="upload-demo" ref="upload" 
          :limit="1"
          action=""
          accept=".geojson"
          :auto-upload="false"
          :on-change ="fileChangeHandler"
          :before-remove ="removeFile"
          >
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <span slot="tip" class="el-upload__tip" style="padding-left:20px;">只能上传geojson文件</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="footer" style="display: flex;float: right; margin-bottom: 20px;">
        <el-button style="margin-left: 15px" type="primary" size="small" @click="confirm">确定</el-button>
        <el-button type="primary" size="small" @click="doCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar';
import OlMap from '@/components/maps/OlMap.vue';
import { getUserInformation } from '@/utils/auth';
import { ROLE } from '@/enum/ROLE';
import { getSiteGroup } from '@/api/site';
import {getForbiddenZones, getForbiddenZoneDetail,addForbiddenZone,updateForbiddenZone,deleteForbiddenZone } from '@/api/zone';
import statusInclude from '@/utils/statusCode';
const GEOMTYPE = {
  circle: 1,
  polygon: 2
};
// 1-禁飞区 2-限飞区 3-授权空域 4-站点作业覆盖范围
const MCTYPE = {
  forbidden: 1,
  limit: 2,
  auth: 3,
  work: 4
};

export default {
  components: {
    SearchBar,
    OlMap
  },
  data() {
    return {
      layerImported: false,
      MCTYPE,
      ROLE,
      map: null,
      GEOMTYPE,
      geometry: null,
      checked: true,
      dialogVisible: false,
      hilightIndex: -1,
      siteList: [],
      edit: false,
      ID: null,
      siteSearchID: null,
      validDate: '',
      validTime: '',
      forbiddenZone: {
        MCName: '新建禁飞区',
        MCType: MCTYPE.forbidden,
        isValid: true,
        bottomHt: 0,
        topHt: 0,
        validDate: [], // 格式: [2022-08-01,2022-09-01]
        validTime: [], // 格式: [9:00,11:00]
        geometries: [],
        PID: null,
        CPID: null,
        siteID: ''
      },

      PIDSearch: null,
      siteIDSearch: null,
      topHt: 0,
      bottomHt: 0,
      typeSearch: 0,
      editZID: null,
      baseList: [],
      list: [
        { MCName: '测试禁飞区1', createTime: new Date().getTime(), shapeType: 1, active: true, address: '', type: 1, isValid: 1, ZID: '3q454', location: '江苏省南京市' },
        { MCName: '测试禁飞区2', createTime: new Date().getTime(), shapeType: 1, active: true, address: '', type: 1, isValid: 1, ZID: 'w3454', location: '江苏省南京市' },
        { MCName: '测试禁飞区3', createTime: new Date().getTime(), shapeType: 1, active: true, address: '', type: 1, isValid: 1, ZID: 'w356454', location: '江苏省南京市' }
      ],
      shrinkLeft: false,
      address: '',
      userRole: ROLE.visitor
    };
  },

  computed:{
   
  },
  watch: {},
  beforeMount() {
    /**
     * 角色获取
     * 超级管理员、管理员：选择公司项目+站点（可选）
     * 企业管理员、项目管理员：选择项目+站点（可选）
     * 操作员：选择项目+站点（必须选站点）
     */
    try {
      this.userRole = getUserInformation().userInformation.roleId;
    } catch (e) {
      this.userRole = ROLE.visitor;
    }
  },

  beforeDestroy() {},
  methods: {
    importLayerHandler() {
      this.dialogVisible = true;
      this.layerImported = true;
    },

    zoneType(type){
      // 1-禁飞区 2-限飞区 3-授权空域 4-站点作业覆盖范围
      const MCTYPE_MAP = new Map([[MCTYPE.forbidden,'禁飞区'],[MCTYPE.limit,'限飞区'],[MCTYPE.auth,'授权空域'],[MCTYPE.work,'站点作业覆盖范围']])
      return MCTYPE_MAP.get(type)
    },

      oneFeatureTransfer(feature){
        let geometry=feature.geometry;
        let geom={}
        if(geometry){
          if(geometry.type==='Polygon'){
            geom.type=2
            if(Array.isArray(geometry.coordinates)){
              debugger
                geom.coordinates=geometry.coordinates[0]
            }else{
                geom.coordinates=geometry.coordinates
            }
          }else if(geometry.type==='Circle'){
            geom.type=1
            geom.coordinates=geometry.coordinates
          }
        }
        return geom
    },
    removeFile(){
      this.forbiddenZone.geometries=[];

    },
    fileChangeHandler(file, list) {
      console.log(file, list);
      var reader = new FileReader();
      reader.readAsText(file.raw);
      reader.onload = evt => {
        var fileString = evt.target.result;
        let layerJson = JSON.parse(fileString);
        console.log(layerJson)
        let geometries=[]
        if(layerJson.type==='Feature'){ // 单个元素
          geometries.push(layerJson.feeture)
        }else if(layerJson.type==='FeatureCollection'){ // 多个元素
        if(layerJson.features&&layerJson.features.length>0){
          layerJson.features.forEach(feature=>{
           let geom =  this.oneFeatureTransfer(feature)
           console.log(feature)
           geometries.push(geom)
          })
        }
        }
        this.forbiddenZone.geometries=geometries
      };
    },
    handleSearchPID(PID) {
      this.PIDSearch = PID;
      this.getSiteList(PID);
      this.getLayerList();
    },

    forbiddenZonePIDHandler(PID) {
      this.forbiddenZone.PID = PID;
      this.getSiteList(PID);
    },
    forbiddenZoneCIDHandler(CID) {
      this.forbiddenZone.CPID = CID;
    },

    forbiddenZoneSiteIDHandler(siteID) {
      this.forbiddenZone.siteID = siteID;
    },

    async getLayerList() {
      this.list = [];
      const {data,code,reason} = await getForbiddenZones(this.PIDSearch,this.siteIDSearch) 
      if (statusInclude(code)) {
        this.list = data.list;
      } else {
        this.$message.error(reason);
      }
    },

    // 设置绘制的几何图形类型
    setGeometry(t) {
      if (t === GEOMTYPE.polygon) {
        this.map.initLayerInteraction(true, 'Polygon', this.forbiddenZone.MCType);
      } else if (t === GEOMTYPE.circle) {
        this.map.initLayerInteraction(true, 'Circle', this.forbiddenZone.MCType);
      }
    },

    btnHandler() {
      if(this.userRole===ROLE.visitor){
          return this.$message.error('访客暂无该权限！');
        }
      if (!this.edit) {
        // 未在编辑，新建
        this.dialogVisible = true;
      } else {
        // 提交 ：新建或者编辑
        if (!this.forbiddenZone.MCName) {
          return this.$message.error('请先填写名称！');
        }
        let geometries = this.map.getSelfLayerGeomotries();
        this.forbiddenZone.geometries = geometries;
        if (geometries.length === 0) {
          return this.$message.error('请先绘制几何图形！');
        }
        if (!this.forbiddenZone.PID) {
          return this.$message.error('未关联项目！');
        }
         // 操作员必须关联站点
        if(this.userRole===ROLE.operator && !this.forbiddenZone.siteID){
          return this.$message.error('未关联站点！');
        }
        console.log(this.forbiddenZone);
        // 项目、站点

        // 时间、日期
      }
    },

    doCancel() {
      this.dialogVisible = false;
      this.map.removeSelfLayerFeature();
      this.map.initLayerInteraction(false);
      this.edit = false;
      this.layerImported=false
      this.forbiddenZone.geometries=[]
      this.ID = null;
    },
    init() {
      this.map = this.$refs.mainMap;
    },

    confirm() {
      this.edit = true;
      this.dialogVisible = false;
      this.map.initLayerInteraction(true, 'Polygon', this.forbiddenZone.MCType);
      this.layerImported=false
      if(this.forbiddenZone.geometries&&this.forbiddenZone.geometries.length>0){
          this.initGeometries(this.forbiddenZone.geometries)
      }
    },

    handleClose() {
      this.dialogVisible = false;
    },

    selectZone(id, index) {
      this.hilightIndex = -1;
      this.ID = id;
    },

    // 在地图上初始化几何图形
    initGeometries(geometries) {
      this.map.initLayerInteraction(true, 'Polygon', this.forbiddenZone.MCType);
      this.map.initSelfLayerGeometries(geometries)
    },

    async getSiteList(pid = null) {
      this.siteList = [];
      const { code, data, reason } = await getSiteGroup(1, 1000, pid);
      if (statusInclude(code)) {
        this.siteList = data.list;
      } else {
        this.$message.error(reason);
      }
    },

    validTimeHandler(e) {
      this.forbiddenZone.validTime = e;
    },

    validDateHandler(e) {
      this.validDate = [];
      if (e && e.length > 1) {
        this.forbiddenZone.validDate = [e[0].getTime(), e[1].getTime()];
      }
    },

    changeType(t) {
      this.type = t;
    },
    async switchChage(active, index, item) {
      const msg = active ? '启用' : '禁用';
      this.$confirm(`确定${msg}[${item.MCName}]禁飞区吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.activeForbiddenZone(item.ZID, active, index);
        })
        .catch(() => {
          this.list[index].active = !active;
        });
    },

    async activeForbiddenZone(id, active, index) {
      const { code, reason } = await this.activeForbiddenZone({ id, active });
      if (statusInclude(code)) {
        this.$message.success('操作成功！');
      } else {
        this.list[index].active = !active;
        this.$message.error(reason);
      }
    },

   
    async beginEdit(id) {
      this.ID = id;
      this.edit = true;
       // 获取禁飞区信息
      const { code, reason, data } = getForbiddenZoneDetail(id );
      if (statusInclude(code)) {
        this.forbiddenZone = data;
        this.initGemetry(this.forbiddenZone);
      } else {
        this.$message.success(reason);
      }
    },
    initGemetry(zone) {
      if (zone.type) {
        // 永久或者临时
      }
    },

    // 删除禁飞区
    async deleteForbiddenZone(id) {
      const { code, reason } = await this.deleteForbiddenZone({ id });
      if (statusInclude(code)) {
        this.$message.success('删除成功！');
      } else {
        this.$message.error(reason);
      }
    },

    beginDel(item) {
      this.$confirm(`确定删除[${item.MCName}]禁飞区吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteZone(item.ZID);
      });
    },
    moreHandler(ZID) {
      if (this.editZID === ZID) {
        this.editZID = null;
      } else {
        this.editZID = ZID;
      }
    },
    siteIDChangeHandler(id) {
      this.clearAllSiteZones();
      this.siteIDSearch = id;
      if (id) {
        this.getLayerList();
        const index = this.siteList.findIndex(item => item.siteID === id);
        const site = this.siteList[index];
        if (site) {
          let hiveModel = null;
          if (site.hiveinfo) {
            hiveModel = site.hiveinfo && site.hiveinfo.hiveModel ? site.hiveinfo.hiveModel : '未知';
          }
          this.map.addHiveMarker(site.siteMode, hiveModel, site.siteLocation[0], site.siteLocation[1], true);
        }
        this.list.forEach(zone => {
          if (zone.siteID === id) {
            this.drawOneZone(zone);
          }
        });
      }
    },

    // 绘制一个静态的禁飞区（不可编辑）
    drawOneZone(zone) {
      if (zone.shapeType === GEOMTYPE.polygon) {
      } else if (zone.shapeType === GEOMTYPE.circle) {
      }
    },

    clearAllSiteZones() {},

    locationCenter() {
      if (points.length) {
        // map.fitBounds(points);
        map.setView(points[points.length - 1]);
      }
    },
    toEdit() {
      this.edit = true;
    },

    async save() {
      const params = {};
      let api = 'add';
      if (this.ID) {
        // @ts-ignore
        params.ID = this.ID;
        api = 'update';
      }
      // @ts-ignore
      const code = await this[`${api}Missions`](params);
    }
  }
};
</script>

<style lang="scss" scoped>
$themeFontColor: #a1a1a1;
$themeBgColor: black;
$themeColor: #30302c;

/deep/ .el-dialog {
  background-color: #080808;
}
/deep/ .el-form-item {
  margin-bottom: 2px;
}
/deep/ .el-form-item {
  width: 100%;
  .el-form-item__content {
    width: 100%;
    .el-cascader {
      width: 100%;
      .el-input {
        width: 100%;
      }
    }
  }
}

/deep/ .el-forbidden {
  .ant-form-item-label > label {
    color: $themeFontColor;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
  margin-bottom: 20px;
  .ant-select-selection {
    color: #fff;
    border: 1px solid #272727 !important;
    background: $themeColor;
  }
  .el-input__suffix {
    display: none;
  }
  .ant-select-arrow {
    color: #989897;
  }
}

.xl-select {
  background-color: #080808;
  border-radius: 0;
  outline: none;
  .ant-select-dropdown-menu-item {
    color: #fff;
  }
  .ant-select-dropdown-menu-item-selected {
    color: #337dfe;
    background-color: #080808;
  }
  .ant-select-dropdown-menu-item-active {
    color: #337dfe;
    background: rgba(255, 255, 255, 0.08) !important;
  }
}
.xl-select-2 {
  .ant-select-dropdown-menu-item {
    text-align: center;
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
$themeFontColor: #a1a1a1;
$themeColor: #0b172f;

::v-deep .el-input__inner,
.el-input-group__append {
  height: 30px;
  border: 0;
  color: $themeFontColor;
  position: relative;
  width: 100%;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-input-group__append {
  height: 30px;
  background-color: $themeColor;
  border: 0;
  border-radius: 2px;
  color: $themeFontColor;
}

.main-zone {
  height: calc(100vh - 80px);
  display: flex;
  position: relative;
  .cancel {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
  }
  .map-custom {
    width: auto;
    flex: 1;
  }
  .modal-left {
    width: 300px;
    position: absolute;
    z-index: 401;
    left: 20px;
    top: 20px;
    background: $themeColor;
    border: 1px solid #6a6a6a;
    padding: 20px 0;
    transition: left 0.5s;
    &.modal-shrink {
      left: -300px;
    }
    .shrink {
      width: 20px;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: -20px;
      cursor: pointer;
    }
    .common-header {
      display: flex;
      align-items: center;
      padding: 0 20px;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #36c6fb;
        margin-right: 16px;
      }
      .title {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .xl-button {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 20px;
      > div {
        margin: auto;
        height: 40px;
        width: 180px;
        color: #1e1e1e;
        font-weight: 500;
        padding: 6px 16px;
        background: #00c5fe;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        &:active {
          transform: scale(0.9);
        }
        &:hover {
          opacity: 0.9;
        }
        img {
          width: 12px;
          margin-right: 4px;
        }
      }
    }
    .layer-mainer {
      position: relative;
      height: calc(100vh - 200px);
      overflow-y: auto;
      padding: 0 20px 20px;
      /deep/.ant-switch {
        height: 22px;
        background-color: #6a6a6a;
        &::after {
          width: 18px;
          height: 18px;
        }
      }
      /deep/.ant-switch-checked {
        background-color: #36c6fb;
        .ant-switch-inner {
          color: #1e1e1e;
        }
      }
      .task-name {
        /deep/.ant-input {
          color: #ffffff;
          background: rgba(106, 106, 106, 0.2);
          border: 0;
          padding: 0 22px;
        }
        padding-top: 20px;
        img {
          width: 14px;
        }
      }
      .task-address {
        display: flex;
        align-items: center;
        margin: 12px 0 5px;
        span {
          font-size: 12px;
          color: $themeFontColor;
          margin-left: 10px;
        }
        img {
          width: 12px;
        }
      }
      .time-range {
        display: flex;
        padding-bottom: 20px;
        .el-input__inner {
          -webkit-appearance: none;
          background-color: $themeColor;
          border-radius: 4px;
          border: 1px solid $themeColor;
          box-sizing: border-box;
          color: white;
          display: inline-block;
          font-size: inherit;
          height: 40px;
          line-height: 40px;
          outline: 0;
          padding: 0 15px;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 100%;
          /deep/ .el-range-input {
            font-size: 13px;
            background: inherit;
            color: white;
          }
          /deep/.el-range-separator {
            color: white;
          }

          /deep/.el-range-separator {
            padding: 0;
          }
        }
      }
      .task-icon-module {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        .module {
          margin-bottom: 20px;
          img {
            width: 48px;
          }
          .text {
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
            margin-top: 10px;
          }
          .unit {
            font-size: 12px;
            color: $themeFontColor;
          }
        }
      }
      .task-line-module {
        .label-module {
          display: flex;
          align-items: center;
        }
        img {
          width: 14px;
        }
        .label {
          color: $themeFontColor;
          font-size: 14px;
          margin: 0 10px;
        }
      }
      .take-off {
        margin: 14px 0 20px;
        display: flex;
        .label {
          font-size: 14px;
          color: rgba(205, 205, 205, 1);
        }
      }
      .tab-c {
        height: 60px;
        padding: 0 12px 0 0;
        .tab {
          width: 260px;
          border-radius: 2px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          .tab-item {
            width: 126px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #1e1e1e;
            border: 1px solid #6a6a6a;
            border-radius: 3px;
            font-size: 14px;
            // font-weight: 500;
            color: $themeFontColor;
            cursor: pointer;
            &.active,
            &:hover {
              color: #1e1e1e;
              background-color: #36c6fb;
            }
          }
        }
      }
      .setting-c {
        width: 100%;
        .operate-panel {
          display: flex;
          justify-content: space-between;
          .lat-lng-module {
            .input-module {
              display: flex;
              align-items: center;
              .label {
                margin-right: 4px;
                color: $themeFontColor;
              }
              /deep/.ant-input {
                width: 108px;
                padding: 4px;
                text-align: center;
                height: 30px;
              }
            }
          }
        }
        .num-c {
          margin-bottom: 8px;
          .head-module {
            position: relative;
            .head-btn {
              position: absolute;
              top: 14px;
              left: 112px;
            }
            /deep/.ant-switch {
              width: 78px;
            }
            .head-follow {
              padding: 12px 0;
              .num-name {
                color: #cdcdcd;
                display: flex;
                align-items: center;
                height: 24px;
                line-height: 24px;
              }
              .num-icon {
                margin-right: 8px;
                width: 14px;
              }
            }
          }
        }
        .select-point {
          width: 100%;
          height: 48px;
          background: rgba(32, 32, 32, 1);
          display: flex;
          align-items: center;
          color: #fff;
        }
      }

      .active {
        box-shadow: 0px 0px 13px 0px rgba(0, 197, 254, 0.45);
      }

      .zone-list {
        height: calc(100% - 260px);
        overflow-y: auto;
        padding-top: 0px;
        .zone {
          &:last-child {
            margin-bottom: 0px;
          }
          width: 100%;
          margin: 0 0 20px 0;
          height: auto;
          // background: #1b1b1b;
          border: 1px solid #3c3c3c;
          color: $themeFontColor;
          padding: 10px;
          display: flex;
          flex-direction: column;
          position: relative;
          > div {
            margin-bottom: 10px;
          }
          cursor: pointer;

          .top {
            display: flex;

            > div {
              margin: auto;
            }
            .edit {
              color: $themeFontColor;
              text-align: center;
              width: 80px;
              position: absolute;
              top: 20px;
              right: 20px;
              border: 1px solid white;
              height: auto;
              background: rgba(30, 30, 30, 0.85);
              border: 1px solid #6a6a6a;
              border-radius: 3px;
              > div {
                height: 40px;
                line-height: 40px;
                &:hover {
                  background-color: #00c5fe;
                  color: black;
                }
              }
            }

            .type-pic {
            }
            .op {
              display: flex;
              flex-direction: column;
              margin-right: 0;
              > div {
                margin-right: 0;
                margin-bottom: 10px;
                img {
                  float: right;
                }
              }
            }
            .main {
              .name {
                color: white;
                line-height: 16px;
              }

              .type {
                font-size: 12px;
                padding: 1px 8px;
                color: #00c5fe;
                border-radius: 3px;
                border: 1px solid #00c5fe;
                margin-top: 5px;
              }
              .type-2 {
                color: red !important;
                border: 1px solid red !important;
              }
            }
          }
          .date {
            margin-left: 13px;
          }
          .location {
            margin-left: 13px;
            img {
              width: 12px;
            }
          }
        }
      }
    }
    .no-point {
      color: #7c7c7c;
      text-align: center;
      margin-top: 60px;
      img {
        width: 60px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
.zone-hangar-icon {
  width: 96px !important;
  height: 70px !important;
  background: url(~@/assets/images/mission/task/site.png);
  background-size: contain;
  div {
    width: 100% !important;
    height: 100% !important;
    font-size: 16px;
    text-align: center;
    line-height: 46px;
    color: #fff;
  }
}
</style>
