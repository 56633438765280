import request from "@/utils/request.js";
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;
function getForbiddenZones(pId, siteID) {
  return request({
    url: `${server}/${base_url.core}/forbiddenzones/list?pId=${pId}&siteID=${siteID}`,
    method: 'GET',
  });
}


function getForbiddenZoneDetail(id) {
  return request({
    url: `${server}/${base_url.core}/forbiddenzones/list?id=${id}`,
    method: 'GET',
  });
}

function addForbiddenZone(data) {
  return request({
    url: `${server}/${base_url.core}/forbiddenzones/add`,
    method: 'POST',
    data
  })
}

function updateForbiddenZone(data) {
  return request({
    url: `${server}/${base_url.core}/forbiddenzones/update`,
    method: 'POST',
    data
  })
}

function deleteForbiddenZone(id) {
  return request({
    url: `${server}/${base_url.core}/forbiddenzones/delete?id=${id}`,
    method: 'delete',
    data
  })
}

export {
  getForbiddenZones,
  addForbiddenZone,
  updateForbiddenZone,
  deleteForbiddenZone,
  getForbiddenZoneDetail

}